@import "./assets/scss/reset.scss";
@import '~bootstrap/scss/bootstrap';
@import '~ngx-toastr/toastr';
@import "./assets/scss/variables.scss";
@import "./assets/scss/helpers.scss";
@import "./assets/scss/icons.scss";
@import "./assets/scss/tables.scss";
@import "./assets/scss/fields.scss";
@import "./assets/scss/datepicker.scss";
@import "./assets/scss/buttons.scss";
@import "./assets/scss/popovers.scss";

*{
    font-family: proxima-nova, sans-serif;
    font-weight: 500;
    color: $text-color;
}

app-navigation{
    z-index: 1;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
*{
    touch-action: manipulation;
}


::placeholder{
    // color: ...;
}

.container{
    padding-left: 16px;
    padding-right: 16px;
    max-width: 1280px;
}
.display-label{
    margin-top: 32px;
    margin-bottom: 8px;
    text-transform: uppercase;
    color: $table-head-blue;
    font-size: 14px;
    line-height: 18px;
}
.display-value{
    color: #222222;
    font-size: 16px;
    line-height: 22px;
}

main{
    min-height: 100vh;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
}
app-numpad{
    width: 100%;
}

.popover-arrow{
    display: none !important;
}

app-empty-state, app-registration-detail{
    width: 100%;
}

// toasts
#toast-container{
    max-width: 100%;
    padding-left: 24px;
    .ngx-toastr{
        max-width: 100%;
        box-shadow: 0 3px 12px 0 rgba(144, 144, 144, 0.2) !important;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 53px;
        padding-right: 30px;
        border-radius: 4px;
        background: #ffffff;
        margin: 0 0 11px;
        width: 400px;
        .toast-close-button{
            background-repeat: no-repeat;
            background-size: contain;
            width: 10px;
            height: 10px;
            transition: .1s ease;
            position: absolute;
            top: 50%;
            right: 13px;
            transform: translatey(-50%);
            &:hover{
                opacity: .7;
            }
            span{
                display: none;
            }
        }
    }
    .toast-success{
        border-left: 5px solid #57CC99;
    }
    .toast-warning{
        border-left: 5px solid #DEA06D;
    }
    .toast-error{
        border-left: 5px solid #DC3545;
    }
    .toast-info{
        border-left: 5px solid #2C70BE;
    }
    .toast-bottom-right {
        right: 16px;
        bottom: 0px;
    }
    .toast-title{
        font-size: 16px;
        color: var(--text-color);
        font-family: "uni_neueregular", sans-serif;
    }
    .toast-message{
        font-size: 13px;
        color: #555555;
        margin-top: 5px;
    }    
}

.d-flex-mobile{

}
.d-none-mobile{

}
@media (min-width: 1050px) {
    .d-flex-mobile{
        display: none !important;
    }
}
@media (max-width: 1049px) {
    .d-none-mobile{
        display: none !important;
    }
    .sticky-top{
        position: sticky;
        top: 0;
    }
    
    .sticky-bottom{
        position: sticky;
        bottom: 0;
        padding-bottom: 5px;
        padding-top: 5px;
        background-color: #ffffff;
    }
}

.saving{
    pointer-events: none;
    opacity: 0.7;
}
