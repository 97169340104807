@import "variables.scss";

.ng-mydp{
    .myDpDisabled{
        background: 0;
        opacity: 0.5;
    }
    .myDpHeaderBtn:focus{
        color: #000 !important;
    }
    .myDpTableSingleDay:hover{
        background-color: $imengine-blue;
        *{
            color: #FFFFFF;
        }
    }
    .myDpSelectedDay{
        background-color: $imengine-blue;
        position: relative;
        *{
            color: #FFFFFF;
        }
        
        
    }
    // .myDpSelectedDay:after{   //adds triangle right of first & second selected day
    //     content: "";
    //     position: absolute;
    //     z-index: 1;
    //     top: 0px;
    //     left: 36px;
    //     border-top: 17px solid transparent;
    //     border-bottom: 17px solid transparent;
    //     border-left: 10px solid #459EE3;
    // }

    .myDpIconRightArrow::before{
        content: "";
        background-image: url(/assets/img/icons/calenderArrow.svg);
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        width: 16.5px;
        height: 7px;
        display: inline-block;
    }
    .myDpIconLeftArrow::before{
        content: "";
        background-image: url(/assets/img/icons/calenderBackArrow.svg);
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        width: 16.5px;
        height: 7px;
        display: inline-block;
    }
}