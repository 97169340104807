.app-button{
    border-radius: 99px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    height: 48px;
    padding: 0 32px;
    transition: .2s ease;
    white-space: nowrap;
    line-height: 20px !important;
    .icon--after{
        margin-left: 12px;
    }
    .icon--before{
        margin-right: 12px;
    }
    &.button-no-delete--red{
        margin-right: 24px;
        filter: brightness(1);
        background-color: #fbe5e7;
        padding: 10px 16px;
        border-radius: 3px;
        font-size: 14px;
        min-width: 140px;
        line-height: 20px;
        *{
            color: $error-red;
        }
    }
    &.button-delete--red{
        font-size: 14px;
        letter-spacing: 1px;
        min-width: 140px;
        background-color: $error-red;
        padding: 10px 16px;
        line-height: 20px;
        border-radius: 3px;
        *{
            color: #ffffff;
        }
    }
    &.button--light{
        background-color: #ECF5FC;
        &:hover{
            background-color: #e0edf6;
        }
    }
    &.button--green{
        background: $button-green;
        *{
            color: #f4f4f4;
            z-index: 1;
        }
        position: relative;
        &::after{
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #26D89F;
            opacity: 0;
            transition: .2s ease;
            pointer-events: none;
            border-radius: 999px;
        }
        &:hover{
            filter: none;
            &::after{
                opacity: 1;
            }
        }
    }
}

.button-link{
    font-size: 20px;
    color: $table-head-blue;
    text-decoration: underline;
    cursor: pointer;
}

.a-link{
    font-size: 20px;
    color: $table-head-blue;
    text-align: center;
    line-height: 48px;
}

.close-link{
    font-size: 12px;
    color: $primary-blue;
    text-decoration: underline;
    cursor: pointer;
}

.disabled{
    pointer-events: none;
    opacity: 0.6;
}