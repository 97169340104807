.popover-body{
 padding: 0;   
}
.popover{
    box-shadow: 0 8px 12px #0000001f;
    border-radius: 0;
    border: 0;
    font-weight: 500;
}

