
.table-wrap{
    &.table-wrap--clickable{
        tr:hover{
            td{
                background-color: #f0f6f7;
                opacity: 1;
            }
        }
    }
}
